<template>
  <aside class="w-full lg:w-max lg:py-6" :class="!open ? 'lg:!h-[98px]' : 'lg:!h-full'">
    <div class="filter-container size-full lg:!w-[464px]">
      <div
        class="h-full lg:max-h-cqh lg:rounded-xl lg:border lg:border-secondary lg:bg-primary lg:px-3 lg:shadow-poster_primary"
      >
        <header v-if="isLgAndUp" class="sticky left-0 top-0 w-full py-3">
          <UiButton text class="flex w-full items-center justify-between" @click="open = !open">
            <UiTitle severity="h5" class="font-bold">Посмотрите, где проходит мероприятие</UiTitle>
            <UiIcon
              name="chevron-down"
              class="size-5 transition-transform"
              :class="{ 'rotate-180': !open }"
            />
          </UiButton>
        </header>
        <TransitionExpand>
          <div v-if="open || isLgAndDown">
            <ul
              class="custom-scrollbar flex !h-fit !max-h-[366px] flex-col overflow-hidden overflow-y-scroll lg:h-auto lg:max-h-auto"
            >
              <li
                v-for="venue in venues"
                :key="venue.id"
                class="w-full rounded-lg border-secondary px-3 py-2 lg:rounded lg:border-b lg:px-1.5 lg:pt-2"
                :class="{ 'rounded bg-primary lg:bg-secondary': activeVenue?.id === venue.id }"
              >
                <UiButton
                  text
                  class="flex w-full items-start gap-1.5 text-left"
                  @click="emit('update:activeVenue', activeVenue?.id === venue.id ? null : venue)"
                >
                  <div
                    class="mt-2.5 hidden size-1.5 min-h-1.5 min-w-1.5 rounded-full bg-main lg:block"
                  />
                  <div>
                    <h6 class="font-bold">
                      {{ venue.title }}
                    </h6>
                    <p v-if="venue.address" class="mb-1.5 text-sm text-text-secondary">
                      {{ venue.address.normalized }}
                    </p>
                    <UiButton
                      tag="RouterLink"
                      :to="{ name: 'VenueView', params: { id: venue.id } }"
                      text
                      link
                    >
                      Перейти на площадку мероприятия
                    </UiButton>
                  </div>
                </UiButton>
              </li>

              <li v-if="!venues.length" class="mt-auto">
                <p class="mb-1.5 text-center text-xs font-medium text-text-primary">
                  Измените или очистите поиск, чтобы увидеть список площадок, где проходит
                  мероприятие
                </p>
              </li>
            </ul>
            <form
              v-if="value.length > 3"
              class="sticky bottom-0 left-0 mt-2 lg:mt-0"
              @submit.prevent
            >
              <UiInput v-model="filter" severity="secondary" type="search" placeholder="Найти">
                <template #append>
                  <UiIcon
                    name="magnifying-glass"
                    class="absolute right-4 top-4 size-4 text-icon-quaternary"
                  />
                </template>
              </UiInput>
            </form>
          </div>
        </TransitionExpand>
      </div>
    </div>
  </aside>
</template>

<script lang="ts" setup>
import { TransitionExpand } from '@morev/vue-transitions'
import type { Nullable } from 'ts-helpers'
import { computed, ref } from 'vue'
import type { Venue } from '@/5_entities/Venue'
import { useBreakpoint } from '@/6_shared/lib'
import { UiButton, UiIcon, UiInput, UiTitle } from '@/6_shared/ui'

type PropType = {
  value: Venue[]
  activeVenue: Nullable<Venue>
}
type EmitType = {
  (e: 'update:activeVenue', venue: Nullable<Venue>): void
}
const props = defineProps<PropType>()
const emit = defineEmits<EmitType>()
const {
  lg: [isLgAndUp, isLgAndDown]
} = useBreakpoint()
const open = ref(false)
const filter = ref('')

const venues = computed(() =>
  props.value.filter((item) => {
    if (!filter.value) return true
    const filterValue = filter.value.toLowerCase()

    return (
      item.title.toLowerCase().includes(filterValue) ||
      item.address?.normalized.toLocaleLowerCase().includes(filterValue) ||
      item.cityName.toLowerCase().includes(filterValue)
    )
  })
)
</script>

<style scoped>
.filter-container {
  container: map;
  container-type: size;

  @media (max-width: 1024px) {
    container-type: normal;
  }
}
.filter-container ul {
  height: calc(100cqh - 48px - 51px) !important;
}
</style>
